import AinsleyGabbani from '~/assets/images/ainsley-gabbani.png'
import ColleenOates from '~/assets/images/colleen-oates.png'
import CourtneyKubitza from '~/assets/images/courtney-kubitza.png'
import DeannEvans from '~/assets/images/deann-evans.png'
import FollowUpCover from '~/assets/images/followup-cover.jpg'
import IanDyck from '~/assets/images/ian-dyck.png'
import LizzieScolari from '~/assets/images/lizzie-scolari.png'
import PaulFitzpatrick from '~/assets/images/paul-fitzpatrick.png'
import RhysFurner from '~/assets/images/rhys-furner.png'
import RituKhanna from '~/assets/images/ritu-khanna.png'
import WarrenPamukoff from '~/assets/images/warren-pamukoff.png'

export default {
  head: {
    title: 'Shopify Partner Education Day',
    keywords: 'Shopify,Partner,Education,Day,Event',
    siteName: 'Shopify',
    description: `Join Shopify's exclusive event, available to all agency, SI and ERP partners to educate and enable you to grow your business with Shopify.`
  },
  common: {
    play: 'play',
    pause: 'pause',
    close: 'close',
    cookie: 'This website uses necessary cookies for analytics and usability purposes',
    navbar: {
      title: 'Welcome to Shopify',
      description: 'Partner Education Day',
      cta: {
        label: 'See Agenda',
        link: '#agenda'
      }
    },
    footer: {
      mainLink: {
        label: 'Visit Shopify.com',
        link: 'https://www.shopify.com/'
      },
      aditionalLinks: [
        {
          label: 'Privacy Policy',
          link: 'https://www.shopify.com/legal/privacy'
        },
        {
          label: 'Terms of Service',
          link: 'https://www.shopify.com/legal/terms'
        }
      ]
    },
    screenMobile: {
      title: 'Desktop and tablet only',
      description:
        'This is a private event that can be accessed only via a desktop or tablet device. If you received an invitation please use the link provided in the email you received.'
    },
    emailScreen: {
      title: 'Welcome to\nPartner Education Day',
      emailForm: {
        email: {
          id: 'email',
          label: 'Enter email address',
          placeholder: 'example@example.com'
        },
        cta: {
          label: 'Get access'
        }
      }
    }
  },
  pageLanding: {
    head: {
      title: 'Partner Education Day',
      keywords: '',
      description: 'Partner Education Day description'
    },
    body: {
      liveStream: {
        chats: {
          apac: 'https://vimeo.com/event/3223300/chat/4c9f009aaa',
          emea: 'https://vimeo.com/event/3223307/chat/08f8099eed',
          namer: 'https://vimeo.com/event/3223313/chat/41e2d6e06f'
        },
        players: {
          apac: [
            {
              title: 'Off',
              player: 'https://vimeo.com/event/3222366/embed/82e9cbc550'
            },
            {
              title: 'ja',
              player: 'https://vimeo.com/event/3223294/embed/1c75654633'
            },
            {
              title: 'zh-CN',
              player: 'https://vimeo.com/event/3223298/embed/56fb4e1252'
            }
          ],
          emea: [
            {
              title: 'Off',
              player: 'https://vimeo.com/event/3223303/embed/955e7a3822'
            },
            {
              title: 'en-US',
              player: 'https://vimeo.com/event/3231627/embed/7071583330'
            }
          ],
          namer: [
            {
              title: 'Off',
              player: 'https://vimeo.com/event/3223312/embed/6560a59f82'
            },
            {
              title: 'en-US',
              player: 'https://vimeo.com/event/3231641/embed/328c6ec5a8'
            }
          ]
        },
        subTitle: 'CC'
      },
      agenda: {
        id: 'agenda',
        title: 'Agenda',
        events: [
          {
            index: '#01',
            title: 'Global Welcome',
            description:
              'Join Shopify’s VP of Partnerships, Ritu Khanna to celebrate 2022 and kick-off an exciting new year.',
            speakers: [
              {
                name: 'Ritu Khanna',
                role: 'VP of Partnerships',
                image: RituKhanna
              }
            ]
          },
          {
            index: '#02',
            title: 'Regional Highlights',
            description:
              'Hear how your regional market is evolving and ways to maximize opportunities with Shopify’s 2023 commerce trends.',
            speakers: [
              {
                name: 'Rhys Furner',
                role: 'Head of Partnerships, APAC',
                image: RhysFurner
              },
              {
                name: 'Courtney Kubitza',
                role: 'Partner Marketing Lead, Scaled Programs',
                image: CourtneyKubitza
              },
              {
                name: 'Deann Evans',
                role: 'Director of EMEA Partnerships & Expansion',
                image: DeannEvans
              },
              {
                name: 'Colleen Oates',
                role: 'Director of Partnerships',
                image: ColleenOates
              }
            ]
          },
          {
            index: '#03',
            title: 'Shopify Partner Program Updates',
            description:
              'Find out how Shopify is transforming the partner program to unlock more ways for you to earn revenue, gain skills and grow your business today.',
            speakers: [
              {
                name: 'Paul Fitzpatrick',
                role: 'Director of Partnerships at Shopify',
                image: PaulFitzpatrick
              }
            ]
          },
          {
            index: '#04',
            title: 'B2B on Shopify',
            description:
              'Hear the latest B2B on Shopify roadmap and releases, learn more about the future of the Plus Wholesale channel and discover how you can maximize the B2B opportunity to grow your business.',
            speakers: [
              {
                name: 'Lizzie Scolari',
                role: 'Product Marketing Manager at Shopify',
                image: LizzieScolari
              }
            ]
          },
          {
            index: '#05',
            title: 'Shopify Checkout and Functions',
            description:
              "In this session, our product team will provide an overview of Checkout Extensibility, including the top merchant use cases, a look at what's planned through 2024, and how partners can start capitalizing on this opportunity today.",
            speakers: [
              {
                name: 'Warren Pamukoff',
                role: 'Product Marketing Lead at Shopify',
                image: WarrenPamukoff
              },
              {
                name: 'Ainsley Gabbani',
                role: 'Sr. Product Marketing Manager at Shopify',
                image: AinsleyGabbani
              }
            ]
          },
          {
            index: '#06',
            title: '2023 Product Highlights',
            speakers: [
              {
                name: 'Ian Dyck',
                role: 'Partner Enablement Lead at Shopify',
                image: IanDyck
              }
            ]
          },
          {
            index: '#07',
            title: 'Building Partnerships for the Long Term',
            speakers: [
              {
                name: 'John Surdakowski',
                role: 'Founder and CEO of Avex Designs'
              },
              {
                name: 'Karel Cardinael',
                role: 'Managing Partner of Radikal'
              },
              {
                name: 'James Denker',
                role: 'VP, Partnerships of Imagination Media'
              },
              {
                name: 'Yamaoka Yoshimasa',
                role: 'CEO of Weblife'
              },
              {
                name: 'Ben Parr',
                role: 'Co-Founder & President of Octane AI'
              }
            ]
          },
          {
            index: '#08',
            title: 'Closing Remarks',
            description:
              'As we close on another Partner Education Day, we want to thank our partners for an incredible year.',
            speakers: [
              {
                name: 'Ian Dyck',
                role: 'Partner Enablement Lead at Shopify',
                image: IanDyck
              }
            ]
          }
        ]
      },
      followUp: {
        cover: FollowUpCover,
        title: 'Explore more Shopify Partner resources',
        description:
          'We’re excited to continue to build together and grow our partnership. Discover more ways to deepen your knowledge with our expanded product and sales enablement resource library in <a href="https://partners.shopify.com/current/resources/certifications" target="_blank">Partner Dashboard</a> and <a href="https://partners.shopify.com/current/resources/certifications" target="_blank">Shopify Certifications</a>.'
      }
    }
  },
  pagePrivate: {
    head: {
      title: 'Private Event',
      keywords: '',
      description: 'Partner Education Day description'
    },
    body: {
      title: 'Private Event',
      description:
        'This is a private event that can only be accessed via magic link! If you received an invitation, please use the link provided in the email in your inbox. '
    }
  }
}
