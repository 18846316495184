export * from 'gsap'
export { gsap } from 'gsap/dist/gsap'
export { Draggable } from 'gsap/dist/Draggable'
export { SplitText } from 'gsap/dist/SplitText'
export { CustomEase } from 'gsap/dist/CustomEase'
export { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin'
export { InertiaPlugin } from 'gsap/dist/InertiaPlugin'
export { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
export { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
export { ScrambleTextPlugin } from 'gsap/dist/ScrambleTextPlugin'
