import { V1_HtmlMetaDescriptor } from '@remix-run/react/dist/routeModules'

import strings from '~/data/strings'

import copy from '~/utils/copy'

const generateHeadProps = (title = '', description = '', keywords = ''): V1_HtmlMetaDescriptor => {
  const parsedTitle = copy
    .parse(
      strings.head.title,
      {
        title: title
          ? copy
              .plain(title)
              .toLowerCase()
              .trim()
              .split(' ')
              .map((s) => s[0].toUpperCase() + s.substring(1))
              .join(' ')
          : ''
      },
      true,
      true
    )
    .trim()
  const parsedDescription = copy.parse(
    strings.head.description,
    { description: description || strings.pageLanding.head.description },
    true,
    true
  )
  const parsedKeywords =
    keywords !== ''
      ? copy.parse(strings.head.keywords, { keywords: keywords || strings.pageLanding.head.keywords }, true, true)
      : ''

  return {
    title: parsedTitle,
    keywords: parsedKeywords,
    description: parsedDescription,
    charset: 'utf-8',
    viewport: 'width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no, viewport-fit=cover',
    'theme-color': '#999',
    'msapplication-config': '/favicons/browserconfig.xml'
  }
}

export default generateHeadProps
